import { useEditor } from "@layerhub-io/react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { authSelector } from "~/store/slices/auth/selectors"
import { selectedProject } from "~/store/slices/project/selectors"
import { syncDesignData } from "~/views/DesignEditor/components/Navbar/DesignTitle"
// import { CreateProjectModal, NavLink, OpenRecentFileModal } from "~/views/DesignEditor/components/Navbar/Navbar"
import React, { Dispatch, SetStateAction } from "react"
import { createNewProject, getAllProjects, updateProjectName, updateSelectedProject } from "~/services/digital-assets"

import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "~/components/ui/dialog"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"

import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { RotateCw, PlusIcon, Clock, SaveAll, Pencil } from "lucide-react"
import { NavLink } from "react-router-dom"

export function CreateProjectModal({
  token,
  setIsOpen,
}: {
  token?: string | null
  setIsOpen: Dispatch<SetStateAction<boolean>>
}) {
  const [name, setName] = useState("")

  function createProject() {
    if (!token) {
      return
    }

    createNewProject({ token, project_name: name })
      .then((data: any) => {
        console.log("Created new project", data)
        // update selected project
        window.history.pushState({}, "", `?project_id=${data.data.id}`)
        window.location.reload()
      })
      .catch((e) => {
        console.error("Failed to create new project", e)
      })
      .finally(() => {
        setIsOpen(false)
      })
  }

  return (
    <Dialog open={true} onOpenChange={setIsOpen}>
      <DialogTitle>Create New Project</DialogTitle>
      <DialogContent>
        <div className="flex flex-col w-full px-4 py-2">
          <div className="flex flex-col">
            <h6 className="text-lg font-bold">Project Name</h6>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border border-gray-300 rounded-md p-2"
            />
          </div>
          <div className="flex gap-2 w-fit mt-4">
            <Button variant="outline" className="mt-2" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button className="mt-2" onClick={createProject}>
              Create Project
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

// Rename project modal
export function RenameProjectModal({
  token,
  setIsOpen,
}: {
  token?: string | null
  setIsOpen: Dispatch<SetStateAction<boolean>>
}) {
  const [name, setName] = useState("")
  const project = useSelector(selectedProject)

  useEffect(() => {
    if (project) {
      setName(project.project_name)
    }
  }, [project])

  function renameProject() {
    if (!token) {
      return
    }

    updateProjectName({ token, project_id: project.id, project_name: name })
      .then((data: any) => {
        console.log("Renamed project", data)
        // update selected project
        window.history.pushState({}, "", `?project_id=${data.data.id}`)
        window.location.reload()
      })
      .catch((e) => {
        console.error("Failed to rename project", e)
      })
      .finally(() => {
        setIsOpen(false)
      })
  }

  return (
    <Dialog open={true} onOpenChange={setIsOpen}>
      <DialogTitle>Rename Project</DialogTitle>
      <DialogContent>
        <div className="flex flex-col w-full px-4 py-2">
          <div className="flex flex-col">
            <h6 className="text-lg font-bold">Project Name</h6>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border border-gray-300 rounded-md p-2"
            />
          </div>
          <div className="flex gap-2 w-fit mt-4">
            <Button variant="outline" className="mt-2" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button className="mt-2" onClick={renameProject}>
              Rename Project
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export function OpenRecentFileModal({
  token,
  setIsOpen,
}: {
  token?: string | null
  setIsOpen: Dispatch<SetStateAction<boolean>>
}) {
  // get recent files

  if (!token) {
    return null
  }

  const [recentFiles, setRecentFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getAllProjects(token)
      .then((data: any) => {
        setRecentFiles(data.data)
      })
      .catch((e) => {
        console.error("Failed to get recent files", e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleUpdate = (project: any) => {
    console.log("Updating selected project", project)
    updateSelectedProject({ token, project_id: project.id })
      .then((data: any) => {
        console.log("Updated selected project", data)
        // update selected project
        window.history.pushState({}, "", `?project_id=${data.data.id}`)
        window.location.reload()
      })
      .catch((e) => {
        console.error("Failed to update selected project", e)
      })
      .finally(() => {
        setIsLoading(false)
        setIsOpen(false)
      })
  }

  // handle search bar
  const [search, setSearch] = useState("")

  // sort by selected
  recentFiles.sort((a: any, b: any) => {
    if (a.is_selected) {
      return -1
    }
    if (b.is_selected) {
      return 1
    }
    return 0
  })

  const filteredFiles = recentFiles.filter((file: any) =>
    file.project_name.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <Dialog open={true} onOpenChange={setIsOpen}>
      <DialogTitle>Open Recent File</DialogTitle>
      <DialogContent>
        <div className="w-full justify-center flex-col items-center">
          <DialogTitle className="mb-2">Open Recent File</DialogTitle>
          {/* search bar */}
          <input
            style={{
              outline: "none",
            }}
            className="px-4 w-full border py-2 h-[40px] rounded-lg"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className="flex justify-center ">
            {isLoading && (
              <div className="p-4">
                {" "}
                {/* <CircularProgress />{" "} */}
                <RotateCw />
              </div>
            )}
          </div>

          <div className="flex flex-col  w-full px-4 py-2">
            {/* <div className="flex flex-col"> */}
            {filteredFiles.map((file: any) => (
              <div key={file.id} onClick={() => handleUpdate(file)} className="flex gap-2">
                {/* <Button onClick={() => handleUpdate(file)} className="flex gap-2">
                  {file.project_name}

                  {file.is_selected ? <CheckCircleOutlined color="secondary" /> : <LaunchOutlined />}
                </Button> */}
                <div className="border rounded-md p-2 my-1 w-full py-2"> {file.project_name} </div>
                {/* <Typograph>
                  {file.project_name}
                </Typograph>
                <Button variant="contained" className="mt-2" onClick={() => updateSelectedProject(file)}>
                  Open
                </Button> */}
              </div>
            ))}
            {/* </div> */}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

function FileComponent() {
  const [isCreateNewProject, setIsCreateNewProject] = useState(false)
  const [isRecentFileOpen, setIsRecentFileOpen] = useState(false)
  const token = useSelector(authSelector).token
  const [isRenaming, setIsRenaming] = useState(false)

  const [isSaving, setIsSaving] = useState(false)

  const syncData = syncDesignData(setIsSaving)

  // on click outside close the dropdown
  //   useEffect(() => {
  //     const handleClick = (e: any) => {
  //       console.log("Clicked", e.target.id)
  //       if (e.target.id !== "file" && e.target.id !== "dropdownButton") {
  //         console.log("Clicked outside")
  //         // setIsFileOpen(false)
  //       }
  //     }
  //     document.addEventListener("click", handleClick)
  //     return () => {
  //       document.removeEventListener("click", handleClick)
  //     }
  //   }, [])

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          style={{
            padding: "0px",
            marginLeft: "20px",
            fontSize: "15px",
            fontWeight: "500",
          }}
          variant={"ghost"}
          id="dropdownButton"
        >
          File
        </Button>
      </PopoverTrigger>
      <PopoverContent className=" p-0 px-2 ">
        <div id="file flex flex-col gap-3">
          {isCreateNewProject && <CreateProjectModal token={token} setIsOpen={setIsCreateNewProject} />}
          {isRecentFileOpen && <OpenRecentFileModal token={token} setIsOpen={setIsRecentFileOpen} />}
          {isRenaming && <RenameProjectModal token={token} setIsOpen={setIsRenaming} />}
          <ul className="py-1 pl-8 my-2 lg:pl-0 rounded-lg" aria-labelledby="dropdownButton">
            <div
              onClick={(e) => {
                e.stopPropagation()
                setIsCreateNewProject(true)
              }}
              className="hover:bg-gray-100 p-2 rounded-lg"
            >
              <a href="#" className="flex  items-center gap-4">
                {" "}
                <PlusIcon className="w-5" /> Create New
              </a>
            </div>
            {/* <div
          onClick={(e) => {
            e.stopPropagation()
          }}
          className=""
        >
          <NavLink name="Open" href="#" icon={<CloudUploadOutlined />} />
        </div> */}
            <div
              onClick={(e) => {
                e.stopPropagation()
                setIsRecentFileOpen(true)
              }}
              className="py-2 hover:bg-gray-100 p-2 rounded-lg flex items-center gap-4"
            >
              {/* Open Recent File */}
              <Clock className="w-5" />
              Open Recent
            </div>
            {/* rename  */}
            <div
              onClick={(e) => {
                e.stopPropagation()
                setIsRenaming(true)
              }}
              className="py-2 hover:bg-gray-100 p-2 rounded-lg flex items-center gap-4"
            >
              <Pencil className="w-5" />
              Rename
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation()
                syncData()
              }}
              className="py-2 hover:bg-gray-100 p-2 rounded-lg flex w-full  items-center gap-4"
            >
              {/* <NavLink name="Save" icon={<CloudSyncOutlined />} /> */}
              <SaveAll className="w-5" />
              Save
            </div>
            {/* <div
          onClick={(e) => {
            e.stopPropagation()
            
          }}
          className=""
        >
          <NavLink name="Save As" href="#" icon={<SaveAsOutlined />} />
        </div> */}
          </ul>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default FileComponent
